<template>
  <div>
    <b-modal
      :title="$t('Alterar nomes dos separadores')"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="showModal"
      :ok-only="(((requestError=== true) || (form_data.length === 0)) ? true : false)"
      :ok-title="(((requestError=== true) || (form_data.length === 0)) ? $t('Fechar') : $t('Guardar'))"
      :ok-variant="(((requestError=== true) || (form_data.length === 0)) ? 'outline-secondary' : 'primary')"
      no-close-on-esc
      no-close-on-backdrop
      @ok="saveChangeTabs"
      @hidden="hiddenModal"
    >
      <b-row>

        <!-- Marcadores -->
        <b-col
          v-if="form_data.length > 0"
          cols="12"
        >
          <validation-observer
            ref="formTabsManager"
            #default="{}"
          >

            <b-row
              v-for="(row, index) in form_data"
              :key="`tab-item-${index}`"
            >
              <b-col cols="12">
                <b-form-group
                  :label="`${row.value_source}`"
                  :label-for="`tab-item-field-${index}`"
                  label-cols-md="2"
                  class="text-md-right text-sm-left pd-75 font-weight-bold"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-input
                      :id="`tab-item-field-${index}`"
                      v-model="row.value"
                      :placeholder="$t('Nome do separador')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

          </validation-observer>
        </b-col>

        <!-- Bloco sem registos -->
        <b-col
          v-if="form_data.length === 0"
          cols="12"
        >
          <b-card
            no-body
            class="shadow-none mb-0"
          >
            <b-card-body>
              <b-card-text>{{ $t('Nenhum separador disponível') }}</b-card-text>
            </b-card-body>
          </b-card>
        </b-col>

      </b-row>

    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BModal, VBModal, VBTooltip, BRow, BCol, BCard, BCardBody, BCardText, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import tabsManagerModule from '@store-modules/crm/tabsManager'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [showMsgRequest],
  props: {
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    ...mapGetters('crm_leads_tabs_manager', ['form_data', 'requestError']),
    ...mapActions('crm_leads', ['updateListviewTabs']),
  },
  async created() {
    try {
      await this.$store.dispatch('crm_leads_tabs_manager/getAllTabsEdition').catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.showModal = true
      this.$root.$emit('app::loading', false)
    } catch (err) {
      //
    }
  },
  methods: {
    hiddenModal() {
      this.$parent.showTabsManagerEdition = false
    },
    async saveChangeTabs(e) {
      if (this.requestError === false) {
        e.preventDefault()
        const self = this

        await this.$refs.formTabsManager.validate().then(success => {
          if (success) {
            self.$root.$emit('app::loading', true)
            this.$store.dispatch('crm_leads_tabs_manager/saveChangeTabs').then(res => {
              self.$root.$emit('app::loading', false)

              if (res.error === 0) {
                this.showMsgSuccessRequest({ message: res.message })
              } else {
                this.showMsgErrorRequest({ message: res.message })
              }

              self.hiddenModal()

              // Atualiza os tabs na listagem
              if (res.error === 0) {
                this.$store.dispatch('crm_leads/updateListviewTabs').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }
            }).catch(error => {
              self.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          } else {
            this.showMsgErrorRequest({ message: this.$t('Preencha os campos obrigatórios') })
          }
        })
      }
    },
  },
  setup() {
    const BOOKMARKS_MODULE_NAME = 'crm_leads_tabs_manager'

    if (!store.hasModule(BOOKMARKS_MODULE_NAME)) {
      store.registerModule(BOOKMARKS_MODULE_NAME, tabsManagerModule)

      onUnmounted(() => {
        if (store.hasModule(BOOKMARKS_MODULE_NAME)) store.unregisterModule(BOOKMARKS_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>
