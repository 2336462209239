import { ref } from '@vue/composition-api'

const defaultState = () => ({
  tabsNames: ref([]),
  tabsCustom: ref([]),
  form_data: ref([]),
  requestError: ref(''),
})

export default defaultState
