import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    tabsNames: state => state.tabsNames.value,
    tabsCustom: state => state.tabsCustom.value,
    form_data: state => state.form_data.value,
    requestError: state => state.requestError.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setTabsNames(state, payload) {
      state.tabsNames.value = []

      if (typeof payload === 'object') {
        Object.keys(payload).forEach(key => {
          state.tabsNames.value[key] = payload[key]
        })
      }
    },
    setTabsCustom(state, payload) {
      state.tabsCustom.value = []

      if (typeof payload === 'object') {
        Object.keys(payload).forEach(key => {
          state.tabsCustom.value[key] = payload[key]
        })
      }
    },
    setFormDataTabs(state) {
      state.form_data.value = []

      if (typeof state.tabsNames.value === 'object') {
        Object.keys(state.tabsNames.value).forEach(key => {
          let valueCustomTab = state.tabsNames.value[key]

          if (key in state.tabsCustom.value) {
            valueCustomTab = state.tabsCustom.value[key]
          }

          const newField = {
            tag: key,
            value_source: state.tabsNames.value[key],
            value: valueCustomTab,
          }

          state.form_data.value.push(newField)
        })
      }
    },
    setRequestError(state, payload) {
      state.requestError.value = payload
    },
  },
  actions: {
    // Obter os tabuladores para editar
    async getAllTabsEdition({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}profileUser/modalCustomNamesTabsLeads`)
          .then(response => {
            try {
              if (typeof response.data.data === 'object') {
                commit('setRequestError', false)
                commit('setTabsNames', response.data.data.namesTabs)
                commit('setTabsCustom', response.data.data.tabsPermLeadsCustom)
                commit('setFormDataTabs')
                resolve(true)
              } else {
                commit('setRequestError', true)
                reject(new Error(i18n.t('Problema ao carregar os tabuladores')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                commit('setRequestError', true)
                reject(new Error(i18n.t('Problema ao carregar os tabuladores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setRequestError', true)
              reject(new Error(i18n.t('Problema ao carregar os tabuladores')))
            }
          })
      })
    },

    // Guarda as alterações da informação dos separadores
    async saveChangeTabs({ state }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('noHaveNameOld', '1')

        state.form_data.value.forEach(row => {
          formData.append(`sw099s04[${row.tag}]`, row.value)
        })

        api.post(`${apiConfig.url_base_api}profileUser/saveCustomNamesTabsLeads`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              if (response.data.error === 0) {
                resolve({ error: 0, message: response.data.msg })
              } else {
                resolve({ error: 1, message: response.data.msg })
              }
            } else {
              reject(new Error(i18n.t('Problema ao guardar os separadores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao guardar os separadores')))
            }
          })
      })
    },
  },
}
